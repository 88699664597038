export function controli18n(code, obj) {
  if (!obj) {
    return ''
  }
  if (code in obj) {
    if (obj[code]) {
      return obj[code]
    }
    return obj.fr
  }
  return obj.fr
}

export const langs = {
  fr: 'Français',
  en: 'English',
  es: 'Español',
  it: 'Italiano',
  de: 'Deutsch'
}
