<template>
  <div class="d-flex items-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div
          style="
            width: 35px;
            position: relative;
            left: 2px;
            padding: 0 10px;
            background-color: #eee;
            border: 1px solid #ccc;
            color: #999;
            font-size: 12px;
            z-index: 5;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          "
          v-bind="attrs"
          v-on="on"
        >
          <strong style="text-transform: uppercase">
            {{ currentLangCode }}
          </strong>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in getLanguagesItems"
          :key="'lang-' + index"
        >
          <v-list-item-title @click="currentLangCode = item.value">{{
            item.text
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-text-field
      v-model="val"
      :placeholder="placeholder"
      :label="label"
      outlined
      hide-details
      :disabled="disabled"
      :dense="dense"
    />
  </div>
</template>

<script>
import { langs } from '@/helpers/langs'

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: { type: String, default: '' },
    orientation: { type: String, default: 'horizontal' },
    disabled: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  data() {
    return {
      dataValue: this.value,
      val: null,
      currentLangCode: 'fr',
    }
  },

  watch: {
    value: {
      handler: function (val) {
        this.dataValue = val

        if (!this.dataValue) {
          this.$set(this, 'dataValue', {})
        }

        Object.entries(langs).forEach(([key]) => {
          if (this.dataValue[key]) {
            this.$set(this.dataValue, key, this.dataValue[key])
          } else {
            this.$set(this.dataValue, key, '')
          }
        })

        this.val = this.dataValue ? this.dataValue[this.currentLangCode] : null
      },
      deep: true,
    },
    val() {
      this.dataValue[this.currentLangCode] = this.val
      this.$emit('input', this.dataValue)
    },
    currentLangCode() {
      this.val = this.dataValue[this.currentLangCode]
    },
  },
  beforeMount() {
    if (!this.dataValue) {
      this.$set(this, 'dataValue', {})
    }

    Object.entries(langs).forEach(([key]) => {
      if (this.dataValue[key]) {
        this.$set(this.dataValue, key, this.dataValue[key])
      } else {
        this.$set(this.dataValue, key, '')
      }
      // this.dataValue[key] = this.dataValue[key] ? this.dataValue[key] : "";
    })

    this.val = this.dataValue[this.currentLangCode]
  },
  computed: {
    getLanguagesItems() {
      return Object.entries(langs).map(([key, value]) => {
        return {
          value: key,
          text: value,
        }
      })
    },
  },
}
</script>

<style></style>
